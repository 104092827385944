<template>
  <div id="divPlainPackageWrapper" class="pp-container">
    <BrandContentSlider
      v-if="contents"
      :slides="contents"
      :config="config"
      logger="plainPackage"
      swiperId="plainPackageSwiper"
    ></BrandContentSlider>
    <div class="hints-container">
      <VueText sizeLevel="7" weightLevel="4" class="hints-title"
        >İşinizi Kolaylaştıracak İpuçları</VueText
      >
      <div class="scrolling-wrapper">
        <div v-for="slide in banners" class="card" :key="slide.title">
          <img :src="slide.image" alt="" @click="goTo(slide.link)" />
        </div>
      </div>
    </div>
    <hr />
    <div class="pp-link-container">
      <VueButton
        :size="sizes.xLarge"
        class="pp-button-link"
        color="white"
        :target="routes.regulationDetails"
        as="router-link"
        :outlined="true"
        :iconName="icon.gavel.name"
        :iconSize="{
          width: icon.gavel.width,
          height: icon.gavel.height,
        }"
        :rightIcon="icon.right"
      >
        Regülasyon Detayları
      </VueButton>
      <VueButton
        :size="sizes.xLarge"
        class="pp-button-link"
        color="white"
        :target="routes.healthWarnings"
        as="router-link"
        :outlined="true"
        :iconName="icon.earth.name"
        :iconSize="{
          width: icon.earth.width,
          height: icon.earth.height,
        }"
        :rightIcon="icon.right"
      >
        Güncel Sağlık Uyarıları
      </VueButton>
    </div>
    <hr />
    <div class="pp-brand-container">
      <div class="head-brand">
        <img src="~@/assets/images/plainpackage.png" alt="head-brand" />
      </div>
      <div class="brand-grid">
        <router-link
          :to="`brand/${product.brandId}`"
          v-for="product in brands"
          :key="product.brandId"
        >
          <img :src="product.image" alt="brand-image" />
        </router-link>
      </div>
    </div>
    <hr />
    <div class="pp-footer-container">
      <BrandButton
        :outlined="true"
        :size="sizes.xLarge"
        :target="routes.faq"
        as="router-link"
        :iconName="icon.info.name"
        :iconSize="{ width: icon.info.width, height: icon.info.height }"
        >Sıkça Sorulan Sorular</BrandButton
      >
    </div>
  </div>
</template>
<script>
import BrandContentSlider from '@/components/brand/Slider/BrandContentSlider';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesStatic from '@/router/routes/secure/RoutesStatic';
import Content from '@/services/Api/content';

export default {
  name: 'PlainPackage',
  components: {
    VueText,
    VueButton,
    BrandButton,
    BrandContentSlider,
  },
  data() {
    return {
      contents: null,
      banners: [],
      brands: [],
      config: null,
      staticRoute: `${RoutesRoot.Secure.path}/${RoutesSecure.Statics.path}/`,
      imageSliderOption: {
        slidesPerView: 1,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  methods: {
    goTo(link) {
      if (link) {
        window.location = link;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('mainAppEllement').scrollTo(0, 0);
    });
  },
  beforeMount() {
    Content.getPlainPackage().then(res => {
      if (res && res.data) {
        const {
          Data: { banners, contents, brands, config },
        } = res.data;

        this.banners = banners;
        this.contents = contents;
        this.brands = brands;
        this.config = config;
      }
    });
  },
  computed: {
    icon() {
      return {
        right: ICON_VARIABLES['right'],
        gavel: ICON_VARIABLES['gavel'],
        earth: ICON_VARIABLES['earth'],
        info: ICON_VARIABLES['info'],
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    routes() {
      return {
        regulationDetails: this.staticRoute + RoutesStatic.RegulationDetails.path,
        healthWarnings: this.staticRoute + RoutesStatic.HealthWarnings.path,
        faq: this.staticRoute + RoutesStatic.FrequentlyAskedQuestions.path,
      };
    },
    media() {
      return {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
    },
  },
};
</script>
<style scoped lang="scss">
@mixin container-spacing {
  margin-top: palette-space-level(30);
  padding-left: palette-space-level(20);
  padding-right: palette-space-level(20);
  margin-bottom: palette-space-level(30);
}

.pp-container {
  .pp-footer-container {
    padding: palette-space-level(20);
  }
  margin-bottom: palette-space-level(40);
  .pp-link-container {
    @include container-spacing;

    .pp-button-link {
      border-radius: 4px;
      border: solid 1px palette-color-level('grey', 30);
      margin-bottom: palette-space-level(20);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      padding: palette-space-level(20);
      display: flex;
      border-radius: palette-space-level(5);
      align-items: center;
      .link-text {
        padding-left: palette-space-level(20);
      }
      .icon-right {
        margin-left: auto;
      }
      .pp-button-content {
        display: flex;
        align-items: center;
      }
    }
  }
  .pp-brand-container {
    @include container-spacing;
    display: flex;
    flex-direction: row;
    .head-brand {
      width: 43%;
      margin-right: palette-space-level(10);
      img {
        object-fit: contain;

        width: 100%;
      }
    }
    .brand-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 57%;
      text-align: right;
      margin-left: palette-space-level(10);
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(-n + 2) {
          align-items: flex-start;
        }
        &:nth-child(n + 5) {
          align-items: flex-end;
        }

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }
    }
  }
  .pp-grid-container {
    @include container-spacing;

    .grid-col-main {
      img {
        object-fit: contain;
        height: 234px;
        width: 136px;
      }
    }
    .grid-col-sub {
      text-align: right;
      img {
        border-radius: palette-space-level(5);
        width: 80px;
        height: 80px;
        object-fit: contain;
      }
    }
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.5;
    border-top: solid 1px #b4c2d3;
  }
  .hints-container {
    margin-top: palette-space-level(35);
    margin-bottom: palette-space-level(30);
    padding-left: palette-space-level(20);
    .hints-title {
      margin-bottom: palette-space-level(10);
    }
    .scrolling-wrapper {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;

      .card {
        flex: 0 0 auto;
        padding: palette-space-level(15);

        img {
          height: 130px;
          width: 190px;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
